/* system模块接口列表 */
import base from "./base";
// 导入http中创建的axios实例
import axios from "@/utils/http";
const system = {
  companyPage(params) {
    // 公司管理
    return axios.get(base.dl + "company", {
      params: params,
    });
  },
  getAllCompany(params) {
    // 所有公司信息
    return axios.get(base.dl + "company/find_by_name", {
      params: params,
    });
  },
  getCompanyByPerson(name) {
    // 获取个人相关公司
    return axios.get(base.dl + "company/find_by_user?name=" + name);
  },
  getAllotByCompany(params) {
    // 获取相关公司相关小组
    return axios.get(base.dl + "allot/child_allot_by_company", {
      params: params,
    });
  },
  addCompanyPage(params) {
    // 公司管理
    return axios.post(base.dl + "company", params);
  },
  companyDetail(id) {
    // 公司详情
    return axios.get(base.dl + "company/" + id);
  },
  editCompany(params) {
    // 编辑公司
    return axios.put(base.dl + "company", params);
  },
  delCompany(id) {
    // 删除公司
    return axios.delete(base.dl + "company/" + id);
  },
  companyElevator(params) {
    // 公司详情页面 电梯列表
    return axios.get(base.dl + "company/elevator_list", {
      params: params,
    });
  },
  getRegion(params) {
    // 公司详情页面 电梯列表
    return axios.get(base.dl + "area/search_region", {
      params: params,
    });
  },
  companyNotElevator(params) {
    // 公司详情页面 未选中电梯列表
    return axios.get(base.dl + "company/not_exist", {
      params: params,
    });
  },
  addElevatorToCompany(params) {
    // 添加电梯到公司
    return axios.post(base.dl + "company/add_elevator", params);
  },
  delElevatorToCompany(params) {
    // 删除公司电梯
    return axios.delete(base.dl + "company/delete_elevator", {
      data: params,
    });
  },
  exportCompany(params) {
    // 导出公司
    return {
      url: base.dl + "company/export",
      params: params,
    };
  },
  elevatorPage(params) {
    // 电梯管理
    return axios.get(base.dl + "elevator", {
      params: params,
    });
  },
  elevatorByContract(params) {
    // 电梯搜索，根据位置和合同
    return axios.get(base.dl + "elevator/show/parts", {
      params: params,
    });
  },
  getQualitySupervision() {
    // 获取质监局
    return axios.get(base.dl + "qualitySupervision/list");
  },
  getElevatorInfo(id, params) {
    // 电梯关联工单展示
    return axios.get(base.dl + "elevator/show/bills/" + id, { params: params });
  },
  addElevator(params) {
    // 新增电梯
    return axios.post(base.dl + "elevator", params);
  },
  editElevator(params) {
    // 编辑电梯
    return axios.put(base.dl + "elevator", params);
  },
  getElevatorDetail(id) {
    // 电梯详情
    return axios.get(base.dl + "elevator/" + id);
  },
  saveAllFloor(id, params) {
    // 保存所有电梯
    return axios.post(
      base.dl + "elevator_floor_compare/floors/all/" + id,
      params
    );
  },
  exportAllElevator(params) {
    // 导出电梯列表
    return axios.post(base.dl + "elevator/screen_export", params, {
      responseType: "blob",
    });
  },
  exportInstall(params) {
    // 导出安装
    return axios.post(base.ja + "installWorkOrder/export",params, {
      responseType: "blob",
    });
  },
  exportOneElevator(params) {
    // 导出单个电梯列表
    return axios.post(base.dl + "elevator/separate_export", params, {
      responseType: "blob",
    });
  },
  batchUpdate(params) {
    // 批量更新
    return axios.put(base.dl + "elevator/batch_update", params);
  },
  addFloor(params) {
    // 新增楼层
    return axios.post(base.dl + "elevator_floor_compare", params);
  },
  editFloor(params) {
    // 编辑楼层
    return axios.put(base.dl + "elevator_floor_compare", params);
  },
  delFloor(id) {
    // 删除楼层
    return axios.delete(base.dl + "elevator_floor_compare/" + id);
  },
  userPage(params) {
    // 用户管理
    return axios.get(base.sq + "user", {
      params: params,
    });
  },
  addUser(params) {
    // 新增用户
    return axios.post(base.sq + "user", params);
  },
  editUser(params) {
    // 编辑用户
    return axios.put(base.sq + "user", params);
  },
  delUser(params) {
    // 禁用用户
    return axios.put(base.sq + "user/user_forbid", params);
  },
  getAllUser() {
    // 获取所有用户登录名
    return axios.get(base.sq + "user/userName/all");
  },
  areaPage(params) {
    // 区域管理
    return axios.get(base.dl + "area_attribute/page", {
      params: params,
    });
  },
  addAreaOne(params) {
    // 新增区域属性
    return axios.post(base.dl + "area_attribute/one", params);
  },
  editAreaOne(params) {
    // 编辑区域属性
    return axios.put(
      base.dl + "area_attribute/one/" + params.id + "?name=" + params.name
    );
  },
  deleteAreaOne(id) {
    // 删除区域属性
    return axios.delete(base.dl + "area_attribute/one/" + id);
  },
  areaByCompany(id) {
    //  区域属性-某公司下区域
    return axios.get(base.dl + "area_attribute/area/" + id);
  },
  personByCompany(id) {
    // 公司-下属所有用户
    return axios.get(base.dl + "company/user_company/" + id);
  },
  reportSave(params) {
    // 新增公司推送设置
    return axios.post(
      base.js + "statisticReportSetting/reportsetting/save",
      params
    );
  },
  reportUpdate(params) {
    // 修改公司推送设置
    return axios.post(
      base.js + "statisticReportSetting/reportsetting/update",
      params
    );
  },
  reportList(id) {
    // 查询公司推送设置
    return axios.get(
      base.js + "statisticReportSetting/reportsetting/list?companyId=" + id
    );
  },
  rolePage(params) {
    // 角色权限id
    return axios.get(base.sq + "role", {
      params: params,
    });
  },
  addRole(params) {
    // 新增角色权限
    return axios.post(base.sq + "role", params);
  },
  editRole(params) {
    // 编辑角色权限
    return axios.put(base.sq + "role", params);
  },
  delRole(params) {
    // 禁用角色
    return axios.put(base.sq + "role/role_forbid", params);
  },
  getAllPermission() {
    // 查询所有权限
    return axios.get(base.sq + "permission");
  },
  getAllAllot(params) {
    // 查询小组
    return axios.get(base.dl + "allot", {
      params: params,
    });
  },
  addAllot(params) {
    // 新增小组
    return axios.post(base.dl + "allot", params);
  },
  editAllot(params) {
    // 编辑小组
    return axios.put(base.dl + "allot", params);
  },
  allotDetail(id) {
    // 小组详情
    return axios.get(base.dl + "allot/" + id);
  },
  allotElevator(params) {
    // 小组详情页面 电梯列表
    return axios.get(base.dl + "allot/elevator_list", {
      params: params,
    });
  },
  allotNotElevator(params) {
    // 小组详情页面 未选中电梯列表
    return axios.get(base.dl + "allot/not_exist", {
      params: params,
    });
  },
  addElevatorToAllot(params) {
    // 添加电梯到小组
    return axios.post(base.dl + "allot/add_elevator", params);
  },
  getEleStatus(id) {
    // 电梯详情-状态报告
    return axios.get(base.dl + "elevator/status/report/" + id);
  },
  delElevatorToAllot(params) {
    // 删除公司电梯
    return axios.delete(base.dl + "allot/delete_elevator", {
      data: params,
    });
  },
  getContractData(params) {
    // 获取合同信息  补全
    return axios.get(base.dl + "contracts/data", {
      params: params,
    });
  },
  uploadContractFile(id, params) {
    // 非保养合同上传附件图片
    return axios.post(
      base.dl + "contracts/file/" + id + "?filePath=" + params.filePath
    );
  },
  getAllotLevel(params) {
    // 获取小组层级
    return axios.get(base.dl + "allot/level", {
      params: params,
    });
  },
  getAllotLevelData(params) {
    // 获取小组层级具体信息
    return axios.get(base.dl + "allot/child_detail_by_company", {
      params: params,
    });
  },
  getAllotParent(params) {
    // 获取小组层级父级
    return axios.get(base.dl + "allot/parent", {
      params: params,
    });
  },
  getUpperAllot(id) {
    // 获取小组负责人
    return axios.get(base.sq + "user/upper/" + id);
  },
  getAllotChild(params) {
    //  传入小组获得该小组及小组以下所有的分组
    return axios.get(base.dl + "allot/child_detail_by_allot", {
      params: params,
    });
  },
  getAllRole() {
    //  角色下拉框
    return axios.get(base.sq + "role/NoPageList");
  },
  getPartElevator(params) {
    // 二维码-获取电梯
    return axios.get(base.dl + "qr_code/elevators", {
      params: params,
    });
  },
  getCodeSign() {
    // 二维码-扫码位置
    return axios.get(base.dl + "qr_code/signs");
  },
  getCodeExport(params) {
    // 二维码-下载文件
    return axios.post(base.dl + "qr_code/export", params, {
      responseType: "blob",
    });
  },
  saveCodeImage(params) {
    // 二维码保存常用logo
    return axios.put(base.dl + "qr_code/image", params);
  },
  importContract(type, params) {
    // 导入合同 合同类型 20_配件；30_修理；40_咨询
    return axios.post(base.dl + "contracts/import/" + type, params);
  },
  getRemindersData(params) {
    // 电梯年检到期列表
    return axios.get(base.dl + "elevator/reminders/pages", { params: params });
  },
  getRemindersContract(params) {
    // 保养合同到期列表
    return axios.post(base.dl + "contracts/pages/reminder", params);
  },
  getRemindersPlan(params) {
    // 维保计划到期列表
    return axios.post(base.dl + "maintenancePlan/pages/reminder", params);
  },
  getRescuerData(params) {
    // 救援人员资质审核管理
    return axios.get(base.dl + "rescuer/pages", {
      params: params,
    });
  },
  setRescueStatus(id, status) {
    // 更新审核状态
    return axios.put(base.dl + "rescuer/status/" + id, { isAudit: status });
  },
  getRescuerOne(id) {
    //  救援人员信息
    return axios.get(base.dl + "rescuer/" + id);
  },
  getBrandLevel() {
    // 获取电梯维保等级
    return axios.get(base.dl + "rescuer/brand/level");
  },
  addEditRescuer(id, params) {
    // 新增编辑维保等级
    return axios.put(
      base.dl + "rescuer/level/" + id + "?levelId=" + params.levelId
    );
  },
  delRescuer(id, params) {
    // 删除维保等级
    return axios.delete(
      base.dl + "rescuer/level/" + id + "?levelId=" + params.levelId
    );
  },
  importElevator(params) {
    // 导入电梯
    return axios.post(base.dl + "elevator/import", params);
  },
  pushableElevator(elevatorNumber, pushable) {
    // 配置电梯是否推送消息
    return axios.put(
      base.sw + "test/message/elevator/" + elevatorNumber + "/" + pushable
    );
  },
  getCodeByNumber(elevatorNumber) {
    // 根据电梯号查询故障代码表
    return axios.get(base.sw + "test/message/fault/elevator/" + elevatorNumber);
  },
  setMulFaultCodeByEle(number, params) {
    // 批量设置故障代码  电梯批量保存故障
    return axios.put(
      base.sw + "test/message/elevatorFault/" + number + "/pushable",
      params
    );
  },
  installProjectDepot(params) {
    //  根据公司Id查询所属模板库
    return axios.get(base.ja + "installProjectDepot/depots", {
      params: params,
    });
  },
  addInstallProjectDepot(params) {
    //  新增模板库
    return axios.post(base.ja + "installProjectDepot/dept", params);
  },
  delInstallProjectDepot(id) {
    //  根据Id删除模板库
    return axios.delete(base.ja + "installProjectDepot/" + id);
  },
  getSelectDepot() {
    // 根据当前登录用户查询配置的模板库
    return axios.get(base.ja + "installDepotCompany/depot");
  },
  setSelectDepot(id) {
    // 根据当前登录用户更新或新增配置的模板库
    return axios.put(base.ja + "installDepotCompany/depot/" + id);
  },
  delSelectDepot() {
    // 根据当前登录用户删除配置的模板库
    return axios.delete(base.ja + "installDepotCompany/depot");
  },
  installProjectConfig(id) {
    // 根据模板库Id查询所有节点
    return axios.get(base.ja + "installProjectConfig/depot/items/" + id);
  },
  addInstallProjectConfig(params) {
    //  新增节点
    return axios.post(base.ja + "installProjectConfig/depot/item", params);
  },
  editInstallProjectConfig(id, params) {
    //  编辑节点
    return axios.put(base.ja + "installProjectConfig/depot/item/" + id, params);
  },
  delInstallProjectConfig(id) {
    //  删除节点
    return axios.delete(base.ja + "installProjectConfig/depot/item/" + id);
  },
  installContractPages(params) {
    // 安装合同展示列表
    return axios.post(base.ja + "installContract/pages", params);
  },
  isExistContract(params) {
    // 根据合同号查询合同是否存在
    return axios.get(base.ja + "installContract/exists", {
      params: params,
    });
  },
  addInstallContract(params) {
    // 新建安装合同
    return axios.post(base.ja + "installContract/one", params);
  },
  installContractInfo(id) {
    // 获取单体安装合同信息
    return axios.get(base.ja + "installContract/" + id);
  },
  getInstallContractEle(id, params) {
    // 获取单体安装合同中绑定的电梯信息
    return axios.get(base.ja + "installContract/elevators/bound/" + id, {
      params: params,
    });
  },
  getInstallContractOtherEle(id, params) {
    // 获取单体安装合同中未绑定的电梯信息
    return axios.get(base.ja + "installContract/elevators/unbound/" + id, {
      params: params,
    });
  },
  addInstallContractEle(id, params) {
    // 安装合同添加电梯
    return axios.post(base.ja + "installContract/elevators/" + id, params);
  },
  delInstallContractEle(contractId, elevatorId) {
    // 删除安装合同中的电梯
    return axios.delete(
      base.ja + "installContract/" + contractId + "/" + elevatorId
    );
  },
  editInstallContractOne(id, params) {
    // 编辑安装合同
    return axios.put(base.ja + "installContract/" + id, params);
  },
  getInstallWorkOrder(params) {
    // 安装工单展示列表
    return axios.post(base.ja + "installWorkOrder/pages", params);
  },
  getInstallDetail(id) {
    // 安装工单详情
    return axios.get(base.ja + "installWorkOrder/detail/" + id);
  },
  getInstallContractJob(params) {
    // 新增工单-合同搜索
    return axios.get(base.ja + "installContract/job", {
      params: params,
    });
  },
  getInstallContractElevator(id, params) {
    // 新增工单-电梯搜索
    return axios.get(base.ja + "installContract/job/" + id, {
      params: params,
    });
  },
  modifyConfig(id,params){
    return axios.put(base.ja + "installWorkOrder/revision/node/" + id,  params.configIds);
  },
  addContractNode() {
    // 新增工单-节点模板库
    return axios.get(base.ja + "installProjectDepot/job");
  },
  addContractNodeConfig() {
    // 新增工单-节点模板配置项
    return axios.get(base.ja + "installProjectConfig/job");
  },
  getFinishedNode(id) {
    // 查询工单已有节点
    return axios.get(base.ja + "installWorkOrder/orderNode/"+ id);
  },
  addInstallWorkOrder(params) {
    return axios.post(base.ja + "installWorkOrder/new", params);
  },
  delInstallWorkOrder(id) {
    return axios.delete(base.ja + "installWorkOrder/" + id);
  },
  setInstallWorkOrderPerson(id, params) {
    // 安装工单-工作改派
    return axios.put(base.ja + "installWorkOrder/reallocate/" + id, params);
  },
  handoverInstallWorkOrder(params) {
    // 安装工单-工作交接
    return axios.put(
      base.ja +
        "installWorkOrder/handOver/" +
        params.previousWorkerId +
        "/" +
        params.nowWorkerId
    );
  },
};

export default system;
