/* eslint-disable */
module.exports = {
  copyright: "Copyright  © 2016-2024 巨立云  iot.joylive.com  All Rights Reserved. 备案号: 苏ICP备14058511号-2",
  cancel: "取消",
  brandManage: "电梯品牌管理",
  totalAmountRO: "总维修工单数",
  standbyHours: "待命时长",
  year: "年度",
  newMaintenanceBatches: "新增维保批次",
  stopOnFloor: "停止楼层",
  illegalCharactersInNineNo: "96333编号含有非法字符",
  approved: "审核通过",
  hoursRO: "维修工时数",
  usedNotDelete: "已使用，不能删除",
  poor: "差",
  distanceLengthForUrgentRepair: "抢修路程历程(公里)",
  setUp: "设置",
  videoPlayback4: "4G视频回放",
  excellent: "优秀",
  paidMaintenance: "有偿保养",
  totalQualifiedRateQDMC: "质量部自行维保检查总合格率",
  selectCompany: "请选择公司",
  entertainmentPlace: "娱乐场所",
  signOut: "退出",
  maintenanceContract: "保养合同",
  version: "版本",
  overload: "超载",
  airport: "机场",
  billingRecord: "开票记录管理",
  elevatorInfo: "电梯档案",
  newPaymentInfo: "新增付款记录",
  statusOffline: "电梯状态: 离线 ",
  fillLoseReason: "请务必填写丢失原因",
  reserved: "保留",
  unfinishedOrderTotal: "未完成总数（待接收，处理中，已暂停）",
  malfunctionNature: "故障性质",
  responseTO: "困人平均响应(分钟)",
  rescuerQualificationManagement: "救援人员资质审核管理",
  upPassingChime: "上到站钟",
  role: "角色",
  savePlanRemark: "保存计划备注",
  deliveryTime: "交付时间",
  inspectionTerms: "核查条件",
  historyRoute: "员工GPS轨迹图",
  viewEleInfo: "查看电梯档案",
  maintenanceBatch: "维保批次",
  notHaveTop: "参考公司没有创建0级小组",
  planExpiring: "维保计划到期列表",
  qualifiedRateNEMC: "新梯收梯维保检查合格率",
  PASSED: "合格",
  normalAnnual: "正常年检",
  result: "处理结果",
  fillInReason: "请务必选择超期理由",
  to612Months: "6-12个月",
  hoursTO: "困人工时数",
  companyNotHaveTop: "该公司没有创建0级小组",
  canNotUpdateMaintenanceStaff: "该公司不可以更新维保人员",
  servicePlatformUnitsList: "服务平台台量表",
  thirdFaultLevel: "故障等级三级",
  selectInspectStatus: "请选择核查结果",
  statusMalfunction: "电梯状态: 故障 ",
  close: "关闭",
  resolved: "已解决",
  lastMaintenanceDate: "上次维保时间",
  address: "详细地址",
  mainSchedule: "保养情况一览表",
  locationContrast: "位置核对",
  qualifiedQDMC: "质量部维保检查合格数",
  sNTwo: "网点二",
  servicePlatformData: "服务平台数据报表",
  statusRecondition: "电梯状态: 检修 ",
  companyData: "公司数据",
  kunshanNo: "昆山119编号",
  commissionMaintenanceUnitsFree: "委托保养台量(免保)",
  renewLostInfo: "管理续签、丢失、开票、收款等信息",
  SUSPEND: "已暂停",
  cloudPush: "云推送",
  doorOpenMode: "开门方式",
  youCanNotDeleteElevator: "已有公司使用该电梯，您不能删除它",
  responseRO: "维修平均响应(分钟)",
  openDoorBtnShow: "开门按钮显示",
  putAway: "收起",
  staffInfo: "员工信息",
  excellentOrderTotal: "优秀评价总数",
  quarterM: "季度保养",
  keywords: "关键词",
  doorOpen: "开门到位",
  lastAnnualInspectionDate: "年检时间",
  qrCodeLogo: "二维码logo",
  factoryNo: "出厂编号",
  intelligenceCode: "智能硬件注册码",
  good: "良好",
  averageResponse: "平均响应(分钟)",
  roleNameContainType: "角色名应包含公司类型",
  notEvaluatedOrderTotal: "未评价总数",
  replacements: "配件",
  tractionFreight: "曳引驱动载货电梯",
  export: "导出",
  ele: "电梯",
  shouldNotBeGreaterThan: "应小于该发票号应收金额",
  area: "维保区域",
  trappedFault: "困人故障",
  maintenanceUnits: "维保台量",
  elevatorNo: "电梯工号",
  carTopInput: "轿顶输入状态",
  totalAmountMO: "总维保工单数",
  noMatchingElevatorFound: "没有匹配的电梯",
  oldPassword: "旧密码",
  chooseScanCode: "请选择是否扫描二维码",
  invoiceIssuerContact: "发票联系人",
  finishedAI: "超期已完成",
  editElevatorBrand: "编辑电梯品牌",
  faultCode: "故障代码",
  videoMonitor: "视频监控",
  codeLocManage: "扫码位置管理",
  success: "成功",
  actualCompletionDate: "实际完成时间",
  nextStep: "下一步",
  maintenanceHeadCount: "保养人头数",
  unsolved: "未解决",
  followUpWorkOrders: "后续维修工单",
  halfAYear: "半年保养",
  aiStatus: "年检状态",
  workOrderManagement: "工单管理",
  workOrderInquiry: "电梯工单查询",
  expiryDate: "合同到期日",
  totalHoursForMaintenance: "保养总工时(小时)",
  statusAll: "电梯状态: 全部 ",
  maintenanceCurrentPerson: "当前维保人",
  generalOrder: "通用工单",
  manufactureDate: "出厂日期",
  selectContract: "请选择应选保养合同",
  end: "结束",
  resendAfter: "秒后可重新发送",
  lostTrustee: "失信被执行人",
  useMode: "您确定使用基础模板吗?",
  ObjectDoesNotExist: "不存在此对象",
  pThree: "产品三",
  companyType: "公司类型",
  paymentMode: "付款方式",
  completeCD: "完整的合同数据库",
  alternativeStaff: "备选人员姓名",
  scanCode: "扫码",
  addContract: "添加保养合同",
  contractNoExists: "合同号已存在",
  satisfactionEvaluation: "满意度统计数据",
  trappedBeing: "困人处理中",
  elevatorStatus: "电梯状态",
  selectLast: "请选择上次年检日期",
  AgedKidsPregnantInCar: "有无老人、小孩、孕妇、特殊人士",
  inOutTerminalState: "主控板输入/输出端子状态",
  shaft: "井道",
  enterOrder: "进场工单",
  nextAnnualInspectionDate: "下次年检日期",
  noMatchingScanPosition: "没有匹配的扫码位置",
  statusInfo: "状态信息",
  UidNotExist: "uid不存在",
  elevatorFile: "电梯档案",
  problemSolvingResponseTime: "解决响应时间(分钟)",
  number: "序号",
  fileNotMatch: "文件不符合要求",
  directSignal: "直达信号",
  carTopOutput: "轿顶输出状态",
  videoChat: "视频通话",
  workBillPush: "工单已推送",
  organizationalStructure: "组织架构",
  order: "顺序",
  automaticSidewalk: "自动人行道梯",
  period: "时间段",
  consignee: "收货联系人",
  maintenanceLevelInfo: "维保等级信息",
  outOfTextLength: "中文最大限制：10",
  contractsInfo2: "保养合同信息",
  tiltAngle: "倾斜角",
  contentNotEmpty: "请填写项次内容",
  invoiceNoExists: "发票号已存在",
  theAmountCollected: "您填写的收款金额",
  insReport: "自检报告",
  generationTime: "生成时间",
  sceneDescription: "现场描述",
  mobilePhone: "移动电话",
  inspectionQualifiedUnits: "官检合格台数",
  repairHeadCount: "抢修人头数",
  statusIncorrect: "状态有误",
  ACCOMPLISH: "已完成",
  elevatorDis: "电梯分布统计",
  loginFails: "登录失败",
  elevatorEvent: "电梯事件",
  other: "其他",
  addElevator: "添加电梯",
  city: "市",
  save: "保存",
  login: "登 录",
  newDue: "新增到期",
  valid: "有效",
  maintenanceUnitsChange: "保养台数变动(单位:台)",
  deletedUnsuccessful: "删除失败",
  confirmPassword: "确认密码",
  validateContract: "合同生效(元/月度)",
  joylive: "巨立云",
  contractInformation: "联系方式",
  areaManager: "区域经理",
  totalAmountCFO: "总用户评价数",
  product: "产品",
  invalidStatus: "无效的状态",
  usernameNotExist: "用户名不存在",
  mobileIncorrect: "请填写正确的电话,格式:158XXXXXXXX",
  relatedIssues: "事宜",
  staffNoAuthority: "该人员无权限维修该电梯",
  unqualifiedSMC: "自行维保检查不合格数",
  hoursMO: "保养工时数",
  to12Years: "1-2年",
  operationNode: "操作节点",
  otherMonth: "其他月份",
  institutionsBuilding: "机关单位",
  sNThree: "网点三",
  contractAwardUnits: "发包台数",
  iOSApp: "iOS版app",
  importedFailed: "导入失败",
  ModeJoylive: "基础模板",
  dueFreeMaintenance: "免保到期",
  unitYuan: "单位：元",
  effectiveDate: "合同生效日",
  residentialBuilding: "住宅小区",
  aRepairRO: "总维修平均修理(分钟)",
  chooseBrand: "请选择品牌",
  faultRate: "故障率",
  everyPageShow: "每页显示",
  enterCompanyName: "请填写公司名称",
  verifyUser: "审核用户",
  statusReport: "状态报告",
  newRepairRecord: "新增报修信息",
  managerNotSame: "负责人和员工账号不能相同",
  noUseFunction: "您无权限使用该功能",
  selectMaintenanceLevelInfo: "请选择维保等级",
  anyoneInjured: "有无人员受伤",
  maintenanceCompany: "维保公司",
  ignoredFaultRecord: "故障忽略记录",
  unqualifiedSMCIn: "部门内自行维保检查不合格数",
  totalHoursOfUrgentRepair: "抢修保养总工时(小时)",
  maintenanceType: "保养类型",
  elevatorMonitoringDetails: "电梯监控详情",
  userFail: "获取当前用户失败",
  chooseRecordOperational: "请选择是否记录操作过程",
  longitude: "经度",
  overhaulPerformed: "大修承接",
  reassignOrder: "工单改派",
  modifyOrder: "工单修改",
  mapView: "电梯聚合分布",
  all: "入网台量",
  elevatorModel: "电梯型号",
  new: "新增",
  thisBrandExists: "已存在此品牌",
  progressReport: "进度报告",
  renewalStatus: "续签状态",
  materialName: "物料名称",
  totalAmount: "合计",
  halfMonth: "半月保养",
  unit: "台量",
  runningTimes: "运行次数",
  groupHas: "该公司0级小组已存在",
  maintenanceTotalUnits: "维保总台量",
  violation: "违规",
  lostManage: "丢失管理",
  totalQDMC: "质量部维保检查总台量",
  contractAmount: "合同金额(元)",
  exposureEvent: "曝光事件",
  renewPaidMaintenance: "有偿续签",
  doYouWantToIgnore: "您确定忽略吗?",
  lastPageText: "尾页",
  annualVolumeStatistics: "年度台量统计",
  unitsSMC: "自行维保检查台量",
  accountReceivable: "应收账款",
  billNumber:"工单号",
  basData: "基础资料",
  warningType:'报警类型',
  accountName: "账号名称",
  companySlogan: "宣传标语",
  totalQualifiedRateNEMC: "新梯收梯维保检查总合格率",
  partOfContractLost: "合同已部分丢失",
  qualifiedNEMC: "新梯收梯维保检查合格数",
  otherStaff: "其他人员",
  energySaving: "节能",
  units: "单位",
  personInfo: "管理维保人员的编号、姓名、状态、工单数量、考评等级等",
  enterBillingDate: "请填写开票日期",
  times: "次",
  notSatisfied: "不满意",
  removeEleNo: "您无权限删除小组电梯数据",
  today: "当月",
  elevatorBrand: "电梯品牌",
  userNoPer: "对不起，您没有权限登录此系统",
  department: "部门",
  monthlyStatistics: "月份工单类型统计",
  picturesNumberLess: "拍照数量不能超过5张",
  vehicleMilesTravelT: "车辆行使里程数(困人)",
  floorsNum: "楼层数",
  selectElevatorsYouNeed: "请选择您需要的电梯",
  serviceStaff: "报障人员",
  reportAnalysis: "报表分析",
  drawer: "开票人",
  yearEnd: "年度保养",
  vehicleMilesTravelM: "车辆行使里程数(保养)",
  staffNameExists: "员工姓名已存在",
  caseTwo: "案例二",
  optimumSize: "宽度：30-107(像素),高度：30-45(像素)",
  urgentRepairManHour: "抢修人工",
  repairTwo: "修理",
  eleListExpiringDate: "电梯年检时间到期列表",
  managerMu: "慕经理",
  theNumberOfTimesVerify: "即将第几次半月保养必须在1-24之间",
  dataOverview: "数据总览",
  signIn: "登录",
  maintenance: "保养",
  maintenanceContractNo: "合同编号",
  relevantCompany: "相关单位",
  aResponseTO: "总困人平均响应(分钟)",
  reason: "理由",
  generalVATInvoice: "普票",
  maintain: "维保",
  paidMaintenanceUnits: "有偿台数",
  CollectionDateIsNotRight: "收款日期不正确",
  eleCanNotDelete: "您不能删除已产生记录的电梯",
  enterReceiptDays: "请填写收款日期",
  rectify: "整改",
  resolvedPartly: "部分解决",
  password: "密码",
  enterStaffName: "请填写员工姓名",
  trappedNumber: "被困人数",
  jan: "1月",
  buzzerOutput: "蜂鸣器输出",
  subcontractCompany: "分包公司",
  fillInEndTime: "请选择结束时间",
  downPassingChime: "下到站钟",
  lightCurtain: "光幕",
  fillTag: "请填写扫码标签",
  selfMaintenanceUnits: "自保台数",
  partialRenew: "部分续签",
  apr: "4月",
  accessoriesNumber: "配件单号",
  handleOrder: "处理工单",
  androidApp: "android版app",
  controllerType: "控制器类型",
  youCanNotDeleteContract: "已有电梯使用该保养合同，您不能删除它",
  inspectPage: "核查页面",
  enterInvoiceNo: "请填写发票号",
  doYouWantToRemove: "您确定移除吗?",
  noRelevantData: "无相关数据",
  productDistribution: "产品分布",
  groupName: "小组名称",
  installationExecution: "安装合同执行",
  exposureTable: "曝光台",
  terminalValid: "端子有效",
  timeBeforeToday: "时间不得小于今天",
  totalPageBeforeText: "共",
  totalHoursMRO: "总保养维修工时数",
  maintenanceService: "维保服务",
  noStats: "无相关数据，请另选其他时间段或区域",
  fillInEleNum: "请填写电梯工号",
  unqualifiedNEMC: "新梯收梯维保检查不合格数",
  invoiceAmountGreaterThan: "开票金额应大于0,格式:0.00",
  selfMaintenanceUnitsFree: "自行保养台量(免保)",
  basicData: "基础数据",
  legalOrg: "法人或其他组织",
  totalQualifiedNEMC: "新梯收梯维保检查总合格数",
  faultHistory: "电梯故障数据库",
  selectOrderType: "请选择工单类型",
  selectElevatorType: "请选择电梯类型",
  takeSteps: "采取措施",
  nominalWidth: "名义宽度",
  staffName: "员工姓名",
  comLogo: "公司商标",
  UnifyTheSocialCreditCode:"统一社会信用代码",
  othersReason: "其他原因",
  putAwayFilter: "收起筛选",
  downloadTemplate: "下载模板",
  staffManagement: "员工管理",
  upCall: "上召",
  speed: "速度",
  press: "气压",
  personCount: "载人数",
  dataSynchronization: "数据同步",
  refresh: "刷新",
  clickForDetails: "点击了解详情",
  updateLog: "更新日志",
  howManyDaysToComplete: "完成所需天数",
  efficiencyReport: "效率报告",
  invoiceDeliveryAdd: "发票邮寄地址",
  invoiceIssuerPhone: "发票联系电话",
  qrOptimumSize: "宽度：30-1080(像素),高度：30-720(像素)",
  topLevel: "0级",
  totalPageAfterText: "页",
  undetermined: "待定",
  amountCFO: "用户评价数",
  anInManage: "年检管理",
  amountOrders: "工单数",
  endTime: "结束时间",
  underConstruction: "功能开发中，敬请期待",
  lostPaidMaintenance: "有偿丢失",
  monitorCheck: "请选择是否查看视频监控",
  groupManagement: "小组管理",
  exportPeriodIncorrect: "导出时间段不正确",
  functionCode: "功能码",
  upkeepContracts: "合同管理",
  doYouWantToReuse: "您确定恢复使用吗?",
  improperUse: "客户使用不当",
  eleListExpiring: "电梯年检时间到期列表",
  operator: "操作人",
  to36Months: "3-6个月",
  exportFilterInaccuracy: "请正确筛选后导出",
  totalUnitsSMC: "自行维保检查总台量",
  view: "查看",
  repairOrderNumbers: "完成维修工单数量",
  school: "学院校园",
  unfinishedRO: "维修工单未完成数",
  certificatePhoto: "证书照",
  dumbWaiter: "杂物电梯",
  workOrder: "工单",
  trapped: "困人",
  sNFour: "网点四",
  unclosed: "未结束",
  telephone: "固定电话",
  serviceCenterIOT: "物联网服务平台数据报表(服务中心总表)",
  maintainCustomerEnvironmentSecurity: "维护客户环境和安全",
  onSiteContactPerson: "现场联系人",
  aug: "8月",
  selectAll: "全选添加",
  changeAccountNot: "账号已使用，不可修改",
  totalAmt: "合计总价",
  amountTO: "困人工单数",
  theNTimeOfMaintenance: "第n次保养",
  remouldBilling: "改造开票",
  name: "名称",
  faultTimes: "故障次数",
  addParentElevators: "添加父级所有电梯",
  projectName: "项目名称",
  lastStaff: "上次保养员",
  relatedMaintenanceOrderNo: "关联保养合同号",
  invalidRequest: "非法请求",
  aResponseRO: "总维修平均响应(分钟)",
  import: "导入",
  pwdFormat: "密码格式：使用8位以上包含数组、字母或特殊字符的组合。",
  prePageText: "上一页",
  description: "描述",
  smsNumber: "通知接收号码",
  video: "视频",
  maintenanceAttr: "维保属性",
  enterProjectName: "请填写项目名称",
  resendCode: "重新发送校验码",
  countInfo: "统计合同相关信息",
  productionDate: "生产日期",
  todoAI: "待年检",
  lableStyle: "标签样式",
  nov: "11月",
  dynamicDetection: "动态检测",
  accessories: "配件商城",
  manageContent: "管理工单相关的所有内容",
  unfinishedOrders: "未完成总数",
  issueInvoice: "开票",
  pendingMatters: "未决事宜",
  unfinishedTO: "困人工单未完成数",
  brandData: "电梯品牌数据",
  firstFaultLevel: "故障等级一级",
  naturalPerson: "自然人",
  residenceName: "小区名称",
  approvalStatus: "审核状态",
  totalTrappedRate: "总困人率",
  hours: "小时",
  selfInsFinish: "自检已完成",
  yes: "是",
  numberOfPictures: "拍照数量",
  selectParentGroup: "请选择父级名称",
  nodeTwo: "节点二",
  remoteRealTime: "远程实时监控电梯运行情况",
  distanceTimeConsumingForMaintenance: "保养路程耗时(小时)",
  amountRO: "维修工单数",
  softwareTheLatestVersion: "软件已是最新版本",
  viewOrder: "查看工单",
  elevatorNoNotExists: "电梯工号不存在",
  loseReason: "丢失原因",
  maintenanceVolumeStatistics: "保养台量统计",
  instantMessage: "即时消息",
  quotation: "报价",
  disNotRight: "显示楼层格式不正确",
  finance: "财务",
  orderType: "工单类型",
  anySearchInfo: "通过输入任意关键字或选择不同的选项，您可以轻松搜索任意一台产品，并查看它的实时数据",
  totalHoursRO: "总维修总工时数",
  renewalNo: "续签合同号",
  refusedOrderTotal: "工单拒绝总数",
  importedSuccessfully: "已导入",
  billing: "开票(元/月度)",
  beaconNo: "云盒编号",
  logout: "退出",
  totalQualifiedSMC: "自行维保检查总合格数",
  FAILED: "不合格",
  buildingNo: "楼栋号",
  RescueMap:"救援地图",
  deliveryAddress: "收货地址",
  functionSelection: "功能选择",
  userInformation: "用户信息",
  techConsultation: "技术咨询",
  purchaseNo: "采购订单号",
  pushedFailed: "推送失败",
  manageBasicInfo: "管理保养合同基础信息",
  sNFive: "网点五",
  statisticalEndDate: "统计结束日期",
  choosePerson: "选择人员",
  EVALUATION: "待评价",
  newPassword: "新密码",
  completeRateRO: "维修工单完成比例",
  nominalSpeed: "名义速度",
  timingError: "请输入正确的持续时间",
  projectAliasAlreadyExists: "使用位置已存在",
  validPictures: "请填写有效的拍照张数",
  dangerTips: "更改本页面所有内容都会直接影响现场标识张贴及员工作业，请谨慎操作！",
  selectTips: "请选择节点库，否则无法新增安装工单操作",
  DONEREFUSE: "拒绝已处理",
  recondition: "检修",
  dynamicSupervision: "监察动态",
  pendingIssuesOld: "未决事宜",
  complaintsHotline: "投诉电话",
  reset: "重置",
  physicalFloor: "物理楼层",
  commercialAndResidentialBuilding: "商住综合",
  selectGroupName: "请选择小组名称",
  moderateOrderTotal: "一般评价总数",
  contractNo: "合同编号",
  selectNext: "请选择下次年检日期",
  fullLoad: "满载信号",
  doorCloseOutput: "关门输出",
  totalHoursTO: "总困人工时数",
  setFilterButtons: "根据电梯不同的健康状况设置了筛选按钮，一键分类",
  unknown: "未知",
  low: "低",
  maintenanceItemsConfiguration: "维保项次配置",
  completeRateCFO: "用户评价完成比例",
  startTime: "开始时间",
  finishTime: "结束时间",
  workOrderStatus: "工单状态",
  TOFIX: "转维修",
  onlineMonitoring: "远程监控",
  contractNature: "合同性质",
  maintenanceMonth: "保养月报",
  more: "更多",
  staffAccount: "员工账号",
  remouldPerformed: "改造承接",
  numberFiveStar: "满意度(5星)数",
  roleEm: "角色不能为空",
  informationStatistics: "信息统计",
  numberNotFiveStar: "满意度(非5星)数",
  currentMaintenanceUnits: "当前保养台数(单位:台)",
  receivedAmount: "已收款金额(元)",
  newMaintenanceAccount: "新增维保账号",
  completeRateTO: "困人工单完成比例",
  loseContract: "丢失合同",
  newPaidMaintenance: "有偿新增",
  importMaintenanceProgram: "导入维保计划",
  staffState: "员工状态",
  distanceLengthForMaintenance: "保养路程历程",
  companyAddress: "公司地址",
  inspectReason: "不合格原因",
  sendApp: "手机端APP已推送",
  multiBrandLift: "多品牌电梯",
  completeRateMO: "维保工单完成比例",
  noMatchingProjectNameFound: "没有匹配的项目名称",
  feb: "2月",
  displayFilter: "显示筛选",
  searchTerms: "没有找到您期望的内容，请尝试其他搜索词，或拨打巨立云服务热线:0512-36607152",
  num: "数量",
  pTwo: "产品二",
  fillInSTime: "请选择开始时间",
  homeLift: "家用",
  homeLift1: "家用梯",
  deleteElevatorBrand: "您不能删除已使用的电梯品牌",
  completeCFO: "用户评价完成数",
  serviceAttitude: "服务态度",
  contractsInfo: "保养合同信息",
  qualifiedSMCIn: "部门内自行维保检查合格数",
  errorHappened: "出错了",
  ratedLoad: "额定载重",
  forgotPwd: "忘记密码？",
  enterRightNum: "请填写正确的数量,格式:0",
  aLittlePoor: "有点差",
  day: "(天)",
  halfAYearM: "半年保养",
  group: "小组",
  nodeThree: "节点三",
  totalHoursMO: "总保养总工时数",
  specialVATInvoice: "专票",
  created: "已创建",
  minutes: "分钟",
  sendTwo: "派发",
  noRelatedStaff: "无相关人员",
  faultTime: "故障发生时间",
  inspectStatus: "核查状态",
  editDate: "电梯年检时间编辑",
  ACCEPT: "待执行",
  expiringReminder: "到期提醒",
  factoryBuilding: "工厂企业",
  regionAttribute: "区域属性",
  overhaul: "大修",
  checkMaintenance: "查看保养合同",
  noAddressName: "电梯工号,地址,项目名称等",
  eleRemark3: "2、液压乘客电梯及液压载货电梯请选择“液压梯”模板",
  forbidUnsuccessful: "禁用失败",
  keyAccurate: "输入双引号加内容可精确查找，例如:'XXX'",
  eleRemark2: "1、曳引驱动乘客电梯、曳引驱动载货电梯、强制驱动载货电梯及杂物电梯请选择“直梯”模板",
  noCompanyOrTopLevel: "没有匹配的公司或公司没有创建0级数据，无法参考",
  eleRemark1: "此为工单模板类型，具体电梯类型以电梯档案中维护的品种为准。",
  materialNo: "物料编码",
  verificationCodeIs: "您的验证码是",
  qrFormat: "扫码位置格式：2-4个汉字或者2-6个字母、数字组合",
  forbid: "禁用",
  personnelActivityReport: "人员活动报告",
  REFUSE: "已拒绝",
  malfunctionState: "故障状态",
  ineffectiveHoursAnalysis: "非有效工时分析",
  firstPageText: "首页",
  general: "一般",
  halfMonthM: "半月保养",
  usedUnitNoAlreadyExists: "使用单位内编号已存在",
  parallel: "并联",
  annualStatistics: "年度工单类型统计",
  registrationCode: "注册代码",
  hospital: "医院",
  dailyOnDutyTime: "日均在岗时间",
  errorSelectNode: "请选择正确的操作节点",
  temp: "温度",
  renewalContent: "改造内容",
  percentageFiveStar: "满意度(5星)百分比",
  deviceCode: "设备代码",
  positiveDrive: "强制驱动载货电梯",
  totalUnits: "总台数",
  durationNotRight: "合同时长不正确",
  noPersonFind: "该范围内无符合条件的维保人员，已为您扩大搜索范围",
  unfinishedMO: "维保工单未完成数",
  needInvoice: "待开票",
  enterRightPrice: "请填写正确的单价,格式:0.00",
  unqualifiedQDMC: "质量部自行维保检查不合格数",
  paymentInfo: "付款信息",
  dec: "12月",
  caseOne: "案例一",
  amountCollected: "收款金额(元)",
  malfunctionConfirmation: "故障确认",
  newPwd: "新密码",
  hourLineOnDuty: "24小时值班电话",
  remainingDays: "倒计天数",
  maintenanceLevel: "维保等级",
  codeIncorrect: "校验码不正确",
  onlineEmployees: "在线员工",
  illegalCharactersInKunNo: "昆山119编号含有非法字符",
  sendOrder: "派发工单",
  regionIOT: "物联网服务平台数据报表(区域)",
  doYouWantToAddParentElevators: "您确定添加父级所有电梯吗?",
  liNu: "证件号",
  sensorType: "传感器类型",
  humidity: "湿度",
  doorOpenOutput: "开门输出",
  manualQuotation: "人工报价",
  workingHours: "工单规定时长",
  fillInCode: "请填写手机校验码",
  elevatorList: "电梯列表",
  morePhone: "有多个用户同时使用该手机号",
  queryCompany: "安装公司查询",
  mild: "满意",
  dailyStandbyTime: "日均待命时间",
  personal: "个人",
  upDown: "上/下行",
  canceled: "因故取消",
  repairOrderTotal: "维修工单总数",
  driveMode: "驱动方式",
  messagePush: "短信推送",
  route: "轨迹",
  installationManagement: "安装合同管理",
  totalVehicleMilesTravelM: "车辆行使总里程数(保养)",
  roleName: "角色名称",
  currentFloor: "当前楼层",
  hoistingHeight: "提升高度",
  average: "平均值",
  editCollector: "编辑采集器",
  needQR: "您需要添加新的扫码位置",
  elevatorNoAlreadyExists: "电梯工号已存在",
  fillfailedInspectReason: "请务必填写核查不合格的原因",
  maintenanceStaffIOT: "物联网服务平台数据报表(维保员)",
  paymentDateIsNotRight: "付款日期不正确",
  forbidInfo: "已禁用",
  totalRecordsAfterText: "条数据",
  rescueTO: "困人平均解救耗时(分钟)",
  auditConfirmation: "资质审核确认",
  numberOfElevator: "电梯数量",
  sea: "搜索提供",
  emergencyRescue: "应急救援",
  newStaff: "新维保人员",
  newInstallStaff: "接受安装人员",
  search: "搜索",
  sNOne: "网点一",
  contractsExpiring: "保养合同到期列表",
  codeSignalGreater: "扫码标志必须为大于0的数字",
  onlineMaintenance: "在线维保",
  hotel: "宾馆酒店",
  deadline: "截止时间",
  obtainOrderPlan: "获取工单计划",
  roleAuthorization: "角色权限",
  companyManagement: "公司管理",
  sep: "9月",
  propertyCompany: "物业公司",
  enterCompanyType: "请填写公司类型",
  escalator: "扶梯",
  halfMonthUnits: "半月保总数",
  business: "业务",
  fillBrandName: "请填写品牌名称",
  column: "列",
  sellingS: "销售服务",
  elevatorStoppedOrNot: "是否停梯",
  abnormalDescription: "异常描述",
  tagNo: "标签号码",
  remould: "改造",
  ownerNickname: "业主简称",
  averageMaintenanceTimesByUnit: "台均保养次数",
  resetPwd: "重置密码",
  maintenanceContractManagement: "保养合同管理",
  maximum: "最大值",
  aging: "账龄(天)",
  completionStatus: "完成状态",
  freeToPaidMaintenance: "免保转有偿",
  newBillingRecord: "新增开票记录",
  quarter: "季度保养",
  goodOrderTotal: "良好评价总数",
  jul: "7月",
  equipmentNoAlreadyExists: "设备编号已存在",
  jun: "6月",
  dispatch: "已派发",
  orderNumber: "工单编号",
  codeLocExist: "扫码名称已存在",
  overIns: "自检超期",
  language: "zh-cn",
  liftOperation: "电梯实时运行状态",
  averageRepair: "平均修理(分钟)",
  equipmentContractNo: "设备号/合同号",
  breakdownReasonChart: "故障原因分析一览表",
  allOperation: "整体操作",
  allEmployees: "所有员工",
  elevatorExport: "电梯导入",
  generateBatch: "生成批次",
  totalUnqualifiedQDMC: "质量部自行维保检查总不合格数",
  yearEndM: "年度保养",
  enterCorrectNumber: "请填写有效的序号",
  enterRightPaymentAmount: "请填写正确的付款金额,格式:0.00",
  noEvaluation: "未评价",
  secondFaultLevel: "故障等级二级",
  subcontracting: "是否分包",
  newCollector: "新增采集器",
  oldNot: "旧密码不正确",
  propertyContract: "物业联系人",
  queryContract: "安装合同查询",
  collectorManage: "采集器管理",
  hoursMRO: "保养维修工时数",
  otherPremises: "其他场所",
  publicBuilding: "公共场馆",
  joyIOT: "巨立电梯物联网系统",
  monarchSystem: "默纳克系统",
  escalatorSystem: "扶梯系统",
  generalSystem: "通用系统",
  stepSystem: "新时达系统",
  cameraSystem: "摄像机系统",
  liftParameters: "电梯参数",
  month: "月份",
  aliasAlreadyExists: "使用位置已存在",
  fillInAI: "请务必填写年检有效期",
  dailyCompleteOrderNumbers: "日均完成工单",
  selectGroupLevel: "请选择小组层级",
  partialOperation: "部分操作",
  selectPerson: "请选择维保人员",
  invoicedAmount: "已开票金额(元)",
  back: "返回",
  addedSuccessfully: "已添加",
  gopageBeforeText: "&nbsp;转到",
  showRecordsFrom: "显示第",
  content: "内容",
  doYouWantToForbid: "您确定禁用吗?",
  expiringContractUnits: "即将到期合同(单位:台)",
  resetFailed: "重置密码失败",
  resetSuccess: "重置密码成功",
  ignore: "忽略",
  playback: "回放",
  company: "公司",
  parentGroup: "父级小组名称",
  trappedOrderTotal: "困人工单总数",
  unfinished: "未办结",
  otherLogo: "其他商标",
  callInCar: "内召",
  statisticsInfo: "以图表形式分析工单数量、类型、台量、维保人员信息等数据",
  enterEffectiveDate: "请填写合同生效日",
  pendingIssues: "电梯在线隐患统计表",
  maintenanceConfiguration: "维保配置",
  notice: "注意",
  projectAlias: "使用位置",
  theNumberOfTimesToDoNextHalfMonth: "即将第几次半月保养",
  illegalCharactersInElevatorNo: "电梯工号含有非法字符",
  singleElevatorUpkeepCost: "单台保养费(年)",
  durationGreaterThanZero: "合同时长(月)需大于零！",
  repairTotalUnits: "维修总台量",
  trappedOrNot: "是否困人",
  aboveInfo: "以上信息由",
  permission: "权限",
  systemRunningTime: "系统运行时间",
  userName: "用户名",
  companyWeb: "公司网站",
  pleaseFillPartyA: "请填写甲方公司",
  pleaseFillPartyB: "请填写乙方公司",
  qualifiedRateSMC: "自行维保检查合格率",
  businessCoop: "巨立云商务合作",
  resultMore: "搜索结果过多,请输入精确的查找条件",
  pleaseSelect: "请选择省/市/区",
  phoneNumberIncorrect: "电话格式不正确",
  signInAgain: "请重新登录",
  emptySearch: "请至少指定一个条件",
  selectElevators: "请选择应选电梯",
  generalFault: "一般故障",
  itemContent: "项次内容",
  select: "请选择",
  showRecordsToRecords: "条记录",
  userNameEx: "用户名已存在",
  companyName: "公司名称",
  consigneeNo: "收货联系电话",
  pFive: "产品五",
  repairUnits: "维修台量",
  qrCodeToll: "二维码",
  trappedOrder: "困人工单",
  maintenanceOrder: "保养工单",
  qrCodeGenerator: "生成二维码",
  quotationNo: "报价单号",
  payee: "收款人",
  accountsReceivable: "应收账款(元)",
  verySatisfied: "很满意",
  alreadyInspect: "已核查",
  staffInspectsData: "员工考核数据",
  getCode: "获取短信校验码",
  showRecordsTo: "到第",
  loadingData: "正在努力地加载，请稍候...",
  oct: "10月",
  transferUnits: "移交台数",
  fro: "来自",
  timeOfFault: "故障时间",
  confirmNewPwd: "确认新密码",
  chartAnalysis: "图表分析",
  malfunctionStatistics: "故障次数统计",
  orderExport: "导出工单",
  actualCompletionNumber: "实际完成总数",
  maintenancePerformed: "保养承接",
  applyAdvancedUser: "申请高级用户",
  fillInRec: "请务必上传整改单",
  maintenancePlanHasImported: "此维保计划已导入",
  tractionPassenger: "曳引驱动乘客电梯",
  howManyDayVerify: "完成所需天数必须在1-5之间",
  certification: "合格证",
  noRecord: "没有找到匹配的记录",
  selectMenu: "请先勾选此功能的上级菜单",
  aiUpdate: "年检更新",
  enterExpiryDate: "请填写合同到期日",
  notApproved: "审核不通过",
  trappedUnits: "困人台量",
  deleteGroupNo: "您无权限删除0级小组",
  currentMonthMaintenanceTimes: "本月保养次数",
  status: "状态",
  trappedRate: "困人率",
  onStandby: "待命",
  invoiceParty: "开票抬头",
  onlineInstallationInfo: "在线安装管理",
  saveAll: "保存全部",
  clientName: "客户名称",
  totalPrice: "报价总价",
  signingDate: "合同签订日",
  caseFour: "案例四",
  contactPerson: "公司联系人",
  errorInfo: "啊哦，您要查看的页面走丢了！",
  validateBeaconNo: "验证云盒编号",
  mainUnits: "保养台数",
  trappedTimes: "困人次数",
  maintenanceName: "保养名称",
  onlineNum: "在线台量",
  tips:"提示",
  todayFault: "实时故障",
  malfunction: "故障",
  newRenewalContract: "新增续签合同",
  qualityReport: "质量报告",
  malfunctionHandling: "故障处理",
  poorOrderTotal: "差评总数",
  repairOrder: "维修工单",
  statusSelection: "状态筛选",
  inspectResult: "核查结果",
  man: "负责人",
  componentReason: "部件原因",
  groupNameHas: "同级别中，该公司已存在相同小组名称.",
  malfunctionLifts: "发生故障的电梯，根据紧急情况和故障类型，会标示不同符号",
  mar: "3月",
  controlMode: "控制方式",
  repairTimes: "报修次数",
  nineNoAlreadyExists: "96333编号已存在",
  planType: "计划类型",
  may: "5月",
  staffData: "员工数据",
  elevatorManagement: "电梯管理",
  researchDebugging: "研发调试",
  suggestion: "建议",
  cylinderNum: "油缸数量",
  fillInCer: "请务必填写合格证",
  jackingType: "顶升型式",
  overhaulBilling: "大修开票",
  myFavorite: "我的收藏",
  userNameEm: "用户名不能为空",
  totalUnqualifiedNEMC: "新梯收梯维保检查总不合格数",
  codeLocation: "扫码位置",
  doorClose: "关门到位",
  eventStatus: "事件状态",
  hydraulicPassenger: "液压乘客电梯",
  report: "报表",
  ifYouCanNotFindIt: "如果您找不到它,点这个试试",
  nextPageText: "下一页",
  conscious: "自觉",
  mapLocation: "地图定位",
  contractManagement: "合同管理",
  unitsSMCIn: "部门内自行维保检查台量",
  chooseTakePictures: "请选择是否拍照",
  addedFailed: "添加失败",
  contractRemainingDays: "合同剩余天数",
  partsBilling: "配件开票",
  download: "下载",
  high: "高",
  monthlyPayment: "月付",
  host: "主机",
  staffMovement: "员工动向",
  invoiceNo: "发票号",
  invoiceType: "发票类型",
  amountMO: "维保工单数",
  serVio: "严重违规",
  enterElevatorNo: "请填写电梯工号",
  orCode: "组织机构代码",
  maintenanceOrderNumbers: "完成保养工单数量",
  newElevatorBrand: "新增电梯品牌",
  level: "等级",
  passwordEm: "密码不能为空",
  brandExists: "电梯品牌已存在",
  doYouWantToUpdate: "您确定更新吗?",
  qualifiedSMC: "自行维保检查合格数",
  totalQualifiedQDMC: "质量部维保检查总合格数",
  operationTime: "操作时间",
  stateDetection: "状态检测",
  billingDate: "开票日期",
  installationCompany: "安装公司",
  maintenancePlan: "电梯保养计划",
  allActions: "所有电梯的开关门动作、上下行、所在楼层等信息通过物联网采集并实时共享到平台，以动画的形式同步展现",
  allCategories: "所有分类",
  cityPlaceHolder: "例如：XX省XX市XX县/区XXX路",
  repairCompany: "改造、重大修理单位",
  updateInspectStatus: "更新核查状态",
  updatedSuccessfully: "已更新",
  tractionSteel: "曳引钢带家用电梯",
  fillLocation: "请填写扫码位置",
  no: "否",
  overdueReason: "超期理由",
  buttonTipAfterText: "页",
  searchCriteria: "搜索条件",
  fillContractNo: "请填写保养合同编号",
  workHours: "在岗时长",
  problemSolvingTroubleshooting: "问题解决和故障排除",
  ratedSpeed: "额定速度",
  recordWrong: ":数值错误，请检查开票记录有无错误",
  allLose: "整体丢失",
  totalVehicleMilesTravelT: "车辆行使总里程数(困人)",
  delete: "删除",
  fillInOldStaff: "请填写原维保人员",
  fillInStartTime: "请选择开始时间或结束时间",
  total: "工单总数",
  hydraulicFreight: "液压载货电梯",
  freeMaintenanceUnits: "免保台数",
  balancePayment: "有尾款",
  usedUnitNo: "使用单位内编号",
  salesOrderNo: "销售订单号",
  dearUser: "尊敬的用户,欢迎使用我们的产品",
  handover: "工作交接",
  lostType: "失信类型",
  CollectedAmountGreaterThan: "收款金额应大于0,格式:0.00",
  viewScheduler: "保养日程",
  averageMaintenanceTimeConsumingByUnit: "台均保养耗时(小时)",
  changePassword: "修改密码",
  otherStatus: "其他状态",
  repairTotalHours: "修理总工时(小时)",
  unitNoAlreadyExists: "使用单位内编号已存在",
  deliveryOrderNo: "出货单号",
  processed: "已处理",
  takePictures: "拍照",
  numberOfVideo:"视频数量",
  shootVideo:"视频",
  maintenanceRepair: "保养/抢修",
  allLost: "整体丢失",
  online: "在线",
  completionDate: "完成时间",
  inspectReasonChart: "工单不合格原因分析表",
  orderCategory: "工单类别",
  accountAmount: "到账总额",
  renewRecord: "续签记录",
  handler: "处理人员",
  InstallationSupervision: "安装监督",
  directSelling: "是否直销",
  debugOrNot:"是否调试",
  directSelling_yes: "直销",
  directSelling_no: "经销/代销" ,
  trappedUntreated: "困人未处理",
  currentYearBillingRevenue: "当年开票收入(元)",
  overloadSignal: "超载信号",
  aRescueTO: "总困人平均解救耗时(分钟)",
  idCard: "身份证",
  userNotExist: "用户不存在",
  faultLevel: "故障等级",
  realTimeStatus: "实时状态",
  currPageBeforeText: "当前第",
  province: "省",
  productConsultation: "产品咨询",
  selfMaintenanceUnitsPaid: "自行保养台量(有偿)",
  stateless: "无状态",
  lastModifyDate: "更新时间",
  anIn: "年检",
  selectMaintenanceStaff: "选择维保人员",
  inputInfo: "录入信息",
  inspectFailed: "更新核查失败",
  usernameDisabled: "账号已禁用",
  edit: "编辑",
  entrustUnits: "委托台数",
  hydraulicLadder: "液压梯",
  mall: "商场",
  enterStaffAccount: "请填写员工账号",
  equipmentNo: "平台档案号",
  partialLost: "部分丢失",
  workOrderProcess: "工单处理过程",
  machineRoomIntrusion: "机房入侵",
  submitItemTips: "请提交或取消待保存的内容",
  inServiceTime: "投用时间",
  maintenanceAccount: "维保账号管理",
  BillingDateIsNotRight: "开票日期不正确",
  receiptInfo: "收款信息",
  kunNoAlreadyExists: "昆山119编号已存在",
  unitsQDMC: "质量部维保检查台量",
  paidAmount: "已付款金额(元)",
  baseInfo: "基本信息",
  phoneEx: "手机号已存在",
  maintenancePerson: "维保人员",
  contractDuration: "合同时长(月)",
  noContractdata: "无相关合同数据，请重新输入",
  hideShowPagination: "隐藏/显示分页",
  latitude: "纬度",
  partsPerformed: "配件承接",
  distanceTimeConsumingForUrgentRepair: "抢修路程耗时(小时)",
  maintenanceContractPerson: "维保联系人",
  quarterlyUnits: "季度保总数",
  offline: "离线",
  sendTrappedOrder: "派发困人工单",
  current: "电流",
  enterPartyAB: "请填写甲方和乙方公司",
  duePaidMaintenance: "有偿到期",
  anySearch: "任意搜索",
  overdue: "逾期",
  renewManage: "续签管理",
  partialLose: "部分丢失",
  free: "免保",
  email: "电子邮箱",
  productNo: "产品编号",
  phoneNotEmpty: "请填写手机号",
  usernameUidNotExist: "用户名或者uid不存在",
  controller: "控制器",
  fillInNewStaff: "请填写新维保人员",
  middle: "中",
  userDoesNotExist: "不存在此用户",
  manager: "维保负责人",
  manMachineInteraction: "人机互动",
  noticeTips: "如果需要查询时间段内的工单，那么起始时间必须小于截止时间",
  blacklist: "黑名单公告",
  manufacturingCompany: "制造公司",
  sendMessage: "短信已成功推送",
  enterRightAmount: "请填写正确的合同金额,格式:0.00",
  numberSatisfactionEvaluation: "满意度评价总数",
  started: "已开始",
  statisticalStartDate: "统计开始日期",
  newReceiptInfo: "新增收款记录",
  maintenancePhone: "维保电话",
  systemSetting: "系统设定",
  confirm: "确定",
  connected: "已连接智能系统",
  satisfactionFive: "满意度(满分5分)",
  adverseType: "不良类型",
  groupWeb: "集团官网",
  to: "截至",
  disNotEmpty: "显示楼层不能为空",
  operation: "操作",
  officeBuilding: "办公综合",
  repairTimesByUnit: "报修率(次/台)",
  agent: "代理商",
  namePassWrong: "用户名或密码错误，请重新登录",
  equipmentNoShouldBeSix: "设备编号为6位数字",
  invoiceAmount: "开票金额(元)",
  completeRO: "维修工单完成数",
  sentMessageSuccessfully: "短信发送成功",
  expectedReceivableAgeAnalysis: "其中,预期应收账龄分析",
  initiateMaintenance: "发起维修",
  quarterlyPayment: "季付",
  createdOrderFailed: "创建工单失败",
  collectionDays: "收款日期",
  sectionLength: "使用区段长度",
  dailyMaintenance: "日常维修保养管理",
  moreThan3Months: "大于3个月",
  formerStaff: "原维保人员",
  formerInstallStaff: "移交安装人员",
  details: "详情",
  selectMaintenanceType: "请选择保养类型",
  outTerminalState: "主控板输出端子状态",
  totalTrappedTimes: "总困人次数",
  floorsInfo: "楼层信息",
  companyNameAlreadyExists: "该公司名称已存在",
  maintenanceBilling: "保养开票",
  query: "查询",
  billingInformation: "开票信息",
  disFloor: "显示楼层",
  partyA: "甲方公司",
  referenceRange: "参考范围",
  doorLockSignal: "门锁信号",
  failedToGetData: "获取数据失败",
  failure: "失败",
  notSynchronized: "未同步",
  partyB: "乙方公司",
  enterEquipmentNo: "请填写设备编号",
  inspect: "核查",
  regulator: "监管机构",
  unfinishedCFO: "用户评价未完成数",
  region: "区/县",
  updatedUnsuccessful: "更新失败",
  eleInfo: "电梯信息",
  lostDueFreeMaintenance: "免保到期丢失",
  requestExpired: "请求过期",
  records: " 条记录",
  notConnected: "未连接智能系统",
  completeTO: "困人工单完成数",
  update: "更新",
  repairRecord: "报修记录",
  onDuty: "在岗",
  averageMaintenanceUnitsByStaff: "人均保养台数",
  inTerminalState: "主控板输入端子状态",
  onlineInstallation: "在线安装",
  orderNumbers: "工单总数",
  unknownState: "未知状态",
  ownerName: "业主全称",
  roleNameCanNotEmpty: "角色名称不能为空",
  halfAYearUnits: "半年保总数",
  recordOperational: "记录操作过程",
  selectRole: "请选择角色",
  terminalOpenClose: "端子开闭",
  stationNot: "请填写正确的层站门,格式:10/10/10",
  callCenter: "呼叫中心",
  inspectStatistic: "工单合格率图",
  normal: "正常",
  photoEditor: "图片编辑",
  rescueTimeConsuming: "解救耗时(时/分)",
  doYouWantToDelete: "您确定删除吗?",
  voltage: "电压",
  doNotRevealToOthers: "请不要泄露给其他人",
  collectiveSelective: "集选",
  doYouWantToAdd: "您确定添加吗?",
  fillInStaff: "请填写维保人员",
  mainPower: "主机功率",
  inadequateAdjustment: "调整不到位",
  location: "位置",
  stationFormat: "格式:10/10/10",
  noInspect: "未核查",
  paymentDate: "付款日期",
  fileType: "文件类型",
  fileSize: "文件大小",
  fileInfo: "文件信息",
  templateType: "模板类型",
  userInfo: "用户信息",
  nodeOne: "节点一",
  endDate: "结束时间",
  contractType: "合同类型",
  protocolOfBrand: "品牌协议标识",
  none: "无",
  useOccasion: "使用场合",
  signUp: "注册",
  caseThree: "案例三",
  levelInfo: "该公司未得到电梯品牌信息，您无法添加维修等级。",
  yearEndUnits: "全年保总数",
  elevator: "直梯",
  supportStaff: "辅助人员",
  groupLevel: "小组层级",
  allRenew: "整体续签",
  receivedDate: "到款时间",
  checkOrder: "排查工单",
  untreated: "未处理",
  ignored: "已忽略",
  newFreeMaintenance: "免保新增",
  openDoorDelayBtn: "开门延时按钮",
  completeMO: "维保工单完成数",
  companyTEL: "公司热线",
  downCall: "下召",
  lumpSumPayment: "一次总付",
  deleteGroupCanNot: "您不能删除已使用的小组",
  caseStudy: "案例分析",
  semiyearlyPayment: "半年付",
  contractLost: "合同已全部丢失",
  rapidRescue: "迅速的网格救援服务",
  buttonTipBeforeText: "第",
  caseFive: "案例五",
  selectUrgentStaff: "请选择急修人员",
  contractSource: "合同来源",
  totalNEMC: "新梯收梯维保检查总台量",
  notStarted: "未开始",
  repairRO: "维修平均修理(分钟)",
  lastModifier: "更新人",
  layerStationDoor: "层/站/门",
  unitsStatistics: "台量统计数据",
  phoneNo: "手机号",
  basicInfo: "基础信息",
  nick: "姓名",
  totalUnqualifiedSMC: "自行维保检查总不合格数",
  anException: "出现异常",
  event: "事件",
  maintenanceTimes: "保养次数",
  totalUnitsNEMC: "新梯收梯维保检查台量",
  staffAccountExists: "员工账号已存在",
  sTimeBeforeEndTime: "开始时间必须小于结束时间",
  createDate: "创建日期",
  trappedOrderNumbers: "完成困人工单数量",
  qualifiedRateSMCIn: "部门内自行维保检查合格率",
  contractStatus: "合同状态",
  fillSubcontractCompany: "请填写分包公司",
  typicalCase: "典型案例",
  currentStatus: "当前状态",
  responseTime: "响应时间",
  materialQuotation: "材料报价",
  sendLater: "稍后派发(更新)",
  toggle: "切换",
  otherYear: "其他年度",
  PENDING: "待审查",
  caseNo: "备案号",
  workBillInfo: "工单信息",
  trappedAlarm: "困人警报",
  regulations: "法规宣传",
  WAITING: "待接受",
  faultDescription: "故障描述",
  runningState: "运行状态",
  gridRescue: "网格救援",
  connecting: "连接中...",
  uploadAttachment: "上传附件",
  fillUpdateContent: "请填写更新内容",
  minimum: "最小值",
  bstSystem: "贝思特系统",
  statusNormal: "电梯状态: 正常 ",
  date: "日期",
  averageMaintenanceTimesByStaff: "人均保养工时(小时)",
  performanceEvaluation: "业绩统计数据",
  abnormalCo: "通讯异常",
  checkYourAPP: "发生困人事件，请关注派单系统",
  userLock: "当前用户已锁定",
  spec: "规格",
  generalO: "通用",
  MondayFri: "周一至周五",
  from: "自",
  brandNo: "品牌编号",
  permissionNotExist: "不存在此权限",
  unaudited: "未审核",
  audited: "已审核",
  totalQualifiedSMCRate: "自行维保检查总合格率",
  brandName: "品牌名称",
  malfunctionConRecord: "故障确认记录",
  nineNo: "96333编号",
  eleFaultChart: "电梯故障情况一览表",
  rejectingTimes: "拒绝总数",
  unmaintained: "未维护",
  finished: "已完结",
  timespan: "时间间隔",
  beaconNoAlreadyExists: "云盒编号已存在",
  reassignFailed: "工单改派失败",
  SEND: "待派发",
  contractPeriod: "合同期限(年)",
  factoryDefault: "出厂默认值",
  serviceCall: "报障电话",
  faultTimesIgnored: "电梯故障次数(人工忽略)",
  person: "人员",
  sendImmediately: "立即派发",
  reconditionState: "检修状态",
  repair: "维修",
  terminalState: "端子状态",
  userManagement: "用户管理",
  roleDoesNotExist: "不存在此角色",
  anInSchedule: "年检管理一览表",
  batchUpdate: "批量更新",
  videoMonitor3: "3G视频监控",
  videoMonitor4: "4G视频监控",
  buildingUsingParty: "使用单位",
  malfunctionIndication: "故障提示",
  totalAmountTO: "总困人工单数",
  paymentAmount: "付款金额",
  newContract: "新增合同",
  passwordDiffer: "两次密码不一致,请重新输入",
  car: "轿厢",
  inUse: "在用",
  station: "维保站",
  elevatorType: "电梯品种",
  quotationPrice: "报价单价",
  countdownExpiryDate: "合同到期倒计时",
  value: "值",
  stationBuilding: "车站",
  qualifiedRateQDMC: "质量部自行维保检查合格率",
  pOne: "产品一",
  trappedTotalUnits: "困人总台量",
  finishedOrders: "完成总数",
  moduleType: "模块种类",
  difStaff: "原维保人员和新维保人员不能相同",
  doYouWantToImport: "您确定导入c盘下的计划表吗？",
  supportedTech: "技术支持内容",
  INHAND: "处理中",
  juliElevator: "巨立电梯股份有限公司",
  elevatorsData: "电梯档案信息表",
  closeDoorBtnShow: "关门按钮显示",
  doYouWantToSelectAll: "您确定全选添加吗?",
  noLatLng: "该电梯没有坐标",
  moreThan2Years: "大于2年",
  topLevelFirst: "请先添加0级小组",
  appFunction: "APP功能",
  pFour: "产品四",
  governmentBuilding: "政府部门",
  newWorkOrder: "新增工单",
  viewWorkOrder: "查看工单",
  input: "请输入",
  enable: "解禁",
  filter: "筛选",
  managerLevel2: "用户管理2级",
  managerLevel3: "用户管理3级",
  addUser: "添加用户",
  banUser: "禁用用户",
  editUser: "编辑用户",
  qrCodeTool: "二维码工具",
  addBlacklist: "添加黑名单",
  editBlacklist: "编辑黑名单",
  deleteBlacklist: "删除黑名单",
  addExposureEvent: "添加曝光事件",
  editExposureEvent: "编辑曝光事件",
  deleteExporeEvent: "删除曝光事件",
  APPMaintenanceModule: "APP维保模块",
  APPLiveDataModule: "APP实时数据模块",
  APPVideoMonitorModule: "APP视频监控模块",
  APPDataCalculationModule: "APP数据统计模块",
  staffLocation: "员工位置",
  workOrderCalculation: "工单统计",
  elevatorLocatioin: "电梯位置",
  removeElevatorBrand: "删除电梯品牌",
  deleteCollector: "删除采集器",
  onlineMonitoringLevel2: "远程监控2级",
  test: "测试",
  productDistributionSplitScreen: "产品分布分屏用",
  checkGridRescue: "查看网格救援",
  addRepairRecord: "新增报修记录",
  editRepairRecord: "编辑报修记录",
  deleteRepairRecord: "删除报修记录",
  annualInspectorLevel2: "年检管理2级",
  annualInspectorLevel3: "年检管理3级",
  annualInspectionDetail: "年检管理详情",
  addMaintenanceStaff: "添加维保人员",
  updateMaintenanceStaff: "编辑维保人员",
  deleteMaintenanceStaff: "删除维保人员",
  serviceCenterrReport: "服务中心报表",
  serviceLife_BreakDownTimeRelation: "使用年限与故障发生次数关系表",
  breakDownAnalyze: "故障分析原因",
  addMaintenanceItem: "新增维保项次",
  editMaintenanceItem: "编辑维保项次",
  deleteMaintenanceItem: "删除维保项次",
  maintenancePlan2: "维保计划",
  editBillingRecord: "编辑开票记录",
  deleteBillingRecord: "删除开票记录",
  exportBillingRecord: "导出开票记录",
  collectionRecord: "收款记录管理",
  editReceiptRecord: "编辑收款记录",
  deleteReceiptRecord: "删除收款记录",
  exportReceiptRecord: "导出收款记录",
  addLog: "添加日志",
  deleteLog: "删除日志",
  exportData: "导出数据",
  syncData: "同步数据",
  elevatorImport: "电梯导入",
  floorsInfoManagement: "楼层信息管理",
  removeElevator: "删除电梯",
  editElevator: "编辑电梯",
  addCompany: "添加公司",
  deleteCompany: "删除公司",
  editCompany: "编辑公司",
  contractImport: "导入合同",
  exportFinanceData: "导出财务数据",
  exportContract: "导出合同",
  editMaintenanceContract: "编辑保养合同",
  deleteMaintenanceContract: "删除保养合同",
  viewMaintenanceContract: "查看保养合同",
  addGroup: "添加小组",
  editGroup: "编辑小组",
  deleteGroup: "删除小组",
  editingAuditRescuersInfo: "编辑审核救援人员信息",
  breakdownReasonAnalysis: "故障原因分析",
  historicalRoute: "历史轨迹",
  addWorkOrder: "新增工单",
  workOrderInspect: "工单核查",
  deleteWorkOrder: "删除工单",
  exportPresentation: "导出报告",
  exportReport: "导出报表",
  exportPendingMatters: "导出未决事宜",
  deleteMaintenanceBatch: "删除维保批次",
  exportMaintenanceProgram: "导出维保计划",
  addMaintenancePlan: "添加维保计划",
  editMaintenancePlan: "编辑维保计划",
  deleteMaintenancePlan: "删除维保计划",
  viewMaintenanceSchedule: "查看保养日程",
  addRoles: "添加角色",
  editRole:"编辑角色",
  disableRole: "禁用角色",
  originalcontract: "合同原件",
  maintenancePlanInspect: "保养计划核查",
  EXAMINED: "已审查",
  custom: "自定义数据",
  Reason: "原因",
  maintainstatus: "维保状态",
  confirmsubmission: "确认提交",
  faultSetting: "故障推送设置",
  elevatorPushable:"电梯故障推送设置",
  faultpushstatusfunction: "故障推送状态功能",
  faultpush: "故障推送",
  pushon: "推送已开启",
  pushclosed: "推送已关闭",
  operationRecord: "弹窗操作记录",
  reportingtime: "上报时间",
  abbreviation: "简称",
  pushMessage: "消息推送",
  menuPermissions: "菜单权限",
  functionitempermission: "功能项权限",
  inspectResponseStatic: "维修工单响应合格率",
  ipcWarning:'报警信息',
  newNode: "新增节点",   
  nodeLibraryDeploy: "节点库配置",  
  nodeContent: "节点内容",   
  inExecution: "执行中", 
  dateOfSigning: "签订日期",  
  contacts: "联系人", 
  representativeA: "甲方代表人", 
  representativeB: "乙方代表人",  
  addressA: "甲方地址", 
  addressB: "乙方地址", 
  telephoneA: "甲方电话", 
  telephoneB: "乙方电话",  
  contractDetails: "安装合同详细",   
  projectAddress: "项目地址", 
  nodeTemplate: "节点模板",  
  elevatorInstall1: "在线安装", 
  installOperationNode: "节点模板库", 
  installWorkOrder: "安装工单管理",  
  installOrderDetail: "安装工单详情",  
  basicNodeConfig: "基础节点库配置",  
  auditor: "审核人员",
  newAuditor: "接受审核人员",
  oldAuditor: "移交审核人员",
  addInstallWorkOrder: "新增安装工单",
  deleteInstallWorkOrder: "删除安装工单",
  editInstallWorkOrder: "编辑安装工单",
  elevatorInstall: "安装管理",
  addNodeLibrary: "新增节点库",
  deleteNodeLibrary: "删除节点库",
  editNodeLibrary: "编辑节点库",
  APPInstallModule: "APP安装模块",
  auditInstallWorkOrder: "审核安装工单",
  addInstallContract: "新增安装合同",
  editInstallContract: "编辑安装合同",
  deleteInstallContract: "删除安装合同",
  editRegionAttribute: "编辑区域",
  deleteRegionAttribute: "删除区域",
  addRegionAttribute: "新增区域",
  noneFunction: "该电梯暂未开通此功能",
  auditOpinion: "审核意见",
  adopt: "通过",
  Failed1: "未通过",
  width: "宽度",
  height: "高度",
  qrCodeLength: "长度在 1 到 10 个字符",
  currentVoltage: "电流/电压",
  executive: "主管人员",
  receiver: "接收人",
  receivingMode: "接收方式",
  elementId: "elementId",
  samedayWorkBillStatistics: "当日工单统计",
  dataOverview2: "数据总览2",
  todayBillTotal: "今日工单总数",
  currentFinishBill: "当前已完成工单数",
  timeOutBill: "即将超时工单数",
  advertManage: "广告管理",
  programManage: "节目管理",
  matterList: "素材管理",
  deviceManage: "终端管理",
  programList: "节目列表",
  createProgram: "创建节目",
  packetProgram: "打包节目",
  packetProgramList: "打包节目列表",
  playPlan: "播放计划",
  deviceList: "终端列表",
  audit: "审核",
  preview: "预览",
  releaseProgram: "发布节目",
  device: "设备",
  available: "可用",
  unavailable: "不可用",
  toBeReviewed: "待审核",
  createdDate: "创建时间",
  programDuration: "节目时长",
  timeSlot: "播放时段",
  broadcastDate: "播放日期",
  homeScreen: "主屏",
  secondaryScreen: "副屏",
  playArea: "播放区域",
  batchDelete: "批量删除",
  batchAudit: "批量审核",
  refuse: "拒绝",
  programNotes: "节目备注",
  programName: "节目名称",
  chooseProgram: "请先选择节目！",
  chooseMatter: "请先选择文件！",
  previewAfterEdit: "您尚未编辑节目信息，请重新编辑后预览！",
  deviceName: "终端名称",
  deviceMAC: "终端MAC",
  deviceSN: "终端SN",
  organization: "所属组织",
  deviceDirection: "终端方向",
  horizontalScreen: "横屏",
  verticalScreen: "竖屏",
  programSize: "节目大小",
  programDirection: "节目方向",
  directionAdaptation: "方向适配",
  directionOnErrorTip: "您选择的终端方向适配不可用，请重新选择！",
  picture: "图片",
  document: "文档",
  turnOn: "开",
  turnOff: "关",
  volumn: "音量",
  faceRecognition: "人脸识别",
  doubleScreenSwitch: "双屏开关",
  workingHours: "工作时间",
  programDetails: "节目详情",
  unbundling: "解绑",
  exitPassword: "退出密码",
  deviceInfo: "终端信息",
  bindingTime: "绑定时间",
  registrationTime: "注册时间",
  IPAddress: "IP地址",
  screenInformation: "屏信息",
  deviceMemory: "设备内存",
  deviceStorage: "设备存储",
  versionInformation: "版本信息",
  firmwareVersion: "固件版本",
  applicationVersion: "应用版本",
  screenshot: "截图",
  standby: "待机",
  awaken: "唤醒",
  restart: "重启",
  adjustVolume: "调节音量",
  emptyProgram: "清空节目",
  partsManagement: "部件管理",
  partsMaintenance: "部件维护",
  queryOfProblemParts: "问题部件查询",
  rescueWorkers: "救援人员",
  partName: "部件名称",
  technicalReplacement: "更换技术条件",
  replacementCycle: "更换周期",
  monthly: "月",
  annually: "年",
  remarks: "备注",
  historicalUpdate: "历史更新",
  noHistoricalData: "暂无历史更新数据",
  updateDate: "更新日期",
  updateReason: "更新原因",
  addPart: "添加部件",
  selectPart: "请选择部件",
  qualitySupervisionBureau: "质监局",
  batchElevatorParts: "批量电梯部件管理",
  EUCRN: "设备使用证登记编号",
  batchAudit:"批量审核",
  AuditSignature:"审核签名",
  elevatorIP:"摄像头ID",
  videoURL:"视频URL",
  APPCustomModule:"APP客户模块",
}
/* eslint-disable no-new */