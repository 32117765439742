<template>
  <div class="container" v-title :data-title="$t('i18n.installWorkOrder')">
    <div id="outer-title">{{ $t("i18n.installWorkOrder") }}</div>
    <div v-loading="loading">
      <jl-table :tableData="tableData" @init="init" :options="options" :columns="columns" :operates="operates"
        :total="total" :height="$defined.HEIGHT - 360 + 'px'">
      </jl-table>
    </div>

    <el-dialog :title="$t('i18n.' + title)" v-model="dialogAdd" :close-on-click-modal="false" destroy-on-close>
      <jl-form :columns="addColumns" @onSubmit="submitAddForm" @resetForm="handleDialog('dialogAdd', false)"></jl-form>
    </el-dialog>

    <el-dialog :title="$t('i18n.' + title)" v-model="dialogModify" :close-on-click-modal="false" destroy-on-close>
      <jl-form :columns="modifyColumns" @onSubmit="modifyForm"
        @resetForm="handleModifyDialog('dialogModify', false)"></jl-form>
    </el-dialog>

    <el-dialog :title="$t('i18n.' + title)" v-model="dialogReassign" :close-on-click-modal="false" destroy-on-close>
      <jl-form :columns="reassignColumns" @onSubmit="reassignForm"
        @resetForm="handleDialog('dialogReassign', false)"></jl-form>
    </el-dialog>

    <el-dialog :title="$t('i18n.handover')" v-model="dialogHandover" :close-on-click-modal="false" destroy-on-close>
      <jl-form :columns="handoverColumns" @onSubmit="submitHandoverForm"
        @resetForm="handleDialog('dialogHandover', false)"></jl-form>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "WorkOrder",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "orderNumber",
          label: "orderNumber",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "projectAddress",
          label: "projectAddress",
          align: "left",
          width: 200,
          search: true,
          type: "input",
        },
        {
          prop: "elevatorName",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "currentStatus",
          label: "currentStatus",
          align: "center",
          type: "input",
          search: true,
        },
        // {
        //   prop: "projectLeader",
        //   label: "areaManager",
        //   align: "center",
        //   type: "input",
        //   search: true,
        // },
        {
          prop: "projectLeader",
          label: "areaManager",
          type: "select",
          data: [],
          search: true,
          props: { label: "name", value: "id" },
          rows: false,
          // rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "projectLeaderName",
          label: "areaManager",
          // rules: [{ required: true, message: t("i18n.select") }],
        },

        {
          prop: "installerName",
          label: "InstallationSupervision",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "verifierName",
          label: "auditor",
          // rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "acceptTime",
          label: "startTime",
          align: "center",
          search: true,
          type: "date",
        },
        {
          prop: "accomplishTime",
          label: "finishTime",
          align: "center",
          search: true,
          type: "date",
        },
      ],
      operates: {
        // 列操作按钮
        width: 180,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("新增安装工单"),
            type: "outer",
            method: () => {
              handleDialog("dialogAdd", true);
            },
          },
          {
            id: "2",
            label: "handover",
            icon: "el-icon-connection",
            class: "transparent",
            show: true,
            type: "outer",
            method: () => {
              handleDialog("dialogHandover", true);
            },
          },
          {
            id: "3",
            label: "reassignOrder",
            icon: "el-icon-edit",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              handleDialog("dialogReassign", true, row);
            },
          },
          {
            id: "4",
            label: "details",
            icon: "el-icon-notebook-2",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              let routeData = router.resolve({
                path: "/install/orderDetail",
                query: { id: row.id },
              });
              window.open(routeData.href, "_blank");
            },
          },
          {
            id: "5",
            label: "delete",
            icon: "el-icon-delete",
            show: proxy.$defined.btnPermission("删除安装工单"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              if (row.acceptTime) {
                proxy.$defined.tip("工单已经开始工作，不能删除！");
              } else {
                handleDelete(row.id);
              }
            },
          },
          {
            id: "6",
            label: "modifyOrder",
            icon: "el-icon-edit-outline",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              handleModifyDialog("dialogModify", true, row);
            },
          },
          {
            id: "7",
            label: "export",
            icon: "el-icon-upload2",
            class: "export",
            show: true,
            type: "outer",
            method: (formInline) => {
              exportCompany(formInline);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
      handoverColumns: [
        {
          prop: "contractIds",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "remote",
          multiple: true,
          props: { label: "number", value: "id" },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteMethod(val, "handoverColumns", "contractIds");
          },
          change: (id) => {
            getEleByContract(id);
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "installerId",
          label: "newInstallStaff",
          type: "select",
          data: [{ number: '全选', id: '全选' }],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "oldInstallerId",
          label: "formerInstallStaff",
          type: "select",
          data: [{ number: '全选', id: '全选' }],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },

        {
          prop: "verifierId",
          label: "newAuditor",
          type: "select",
          data: [{ number: '全选', id: '全选' }],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "oldVerifierId",
          label: "oldAuditor",
          type: "select",
          data: [{ number: '全选', id: '全选' }],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },
      ],
      dialogHandover: false,
      dialogModify: false,
      dialogAdd: false,
      dialogReassign: false,
      reassignColumns: [
        {
          prop: "contractId",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "remote",
          props: { label: "number", value: "id" },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteMethod(val, "reassignColumns", "contractId");
          },
          change: (id) => {
            getEleByContract(id);
          },
          rules: [{ required: false, message: t("i18n.input") }],
        },
        {
          prop: "elevatorIds",
          label: "elevatorNo",
          type: "select",
          multiple: true,
          props: { label: "number", value: "id" },
          data: [],
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "installerId",
          label: "InstallationSupervision",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "verifierId",
          label: "auditor",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "configIds",
          label: "nodeContent",
          type: "select",
          multiple: true,
          props: { label: "item_simple_name", value: "id" },
          data: [],
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "id",
          show: false,
        },
      ],
      // modifyColumns2: [
      //   {
      //     prop: "contractId",
      //     label: "maintenanceContractNo",
      //     align: "center",
      //     search: true,
      //     type: "remote",
      //     props: { label: "number", value: "id" },
      //     loading: false,
      //     data: [],
      //     remoteMethod: (val) => {
      //       remoteMethod(val, "modifyColumns", "contractId");
      //     },
      //     change: (id) => {
      //       getEleByContract(id);
      //     },
      //     rules: [{ required: false, message: t("i18n.input") }],
      //   },
      //   {
      //     prop: "elevatorIds",
      //     label: "elevatorNo",
      //     type: "select",
      //     multiple: true,
      //     props: { label: "number", value: "id" },
      //     data: [],
      //     rules: [{ required: false, message: t("i18n.select") }],
      //   },
      //   {
      //     prop: "installerId",
      //     label: "handler",
      //     type: "select",
      //     data: [],
      //     props: { label: "name", value: "id" },
      //     rules: [{ required: false, message: t("i18n.select") }],
      //   },
      //   {
      //     prop: "verifierId",
      //     label: "auditor",
      //     type: "select",
      //     data: [],
      //     props: { label: "name", value: "id" },
      //     rules: [{ required: false, message: t("i18n.select") }],
      //   },
      //   {
      //     prop: "configIds",
      //     label: "nodeContent",
      //     type: "select",
      //     multiple: true,
      //     props: { label: "item_simple_name", value: "id" },
      //     data: [],
      //     rules: [{ required: false, message: t("i18n.select") }],
      //   },
      //   {
      //     prop: "id",
      //     show: false,
      //   },
      // ],
      addColumns: [
        {
          prop: "contractId",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "remote",
          props: { label: "number", value: "id" },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteMethod(val, "addColumns", "contractId");
          },
          change: (id) => {
            getEleByContract(id);
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "elevatorIds",
          label: "elevatorNo",
          type: "select",
          multiple: true,
          props: { label: "number", value: "id" },
          data: [{ number: '全选', id: '全选' }],
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "installerId",
          label: "InstallationSupervision",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "verifierId",
          label: "auditor",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },
        // {
        //   prop: "configIds",
        //   label: "nodeContent",
        //   type: "select",
        //   multiple: true,
        //   props: { label: "item_simple_name", value: "id" },
        //   data: [],
        //   rules: [{ required: true, message: t("i18n.select") }],
        // },
        {
          prop: "id",
          show: false,
        },
      ],
      modifyColumns: [
        {
          prop: "contractId",
          label: "maintenanceContractNo",
          align: "center",
          // search: true,
          // type: "input",
          // props: { label: "number", value: "id" },
          // loading: false,
          // data: [],
          // rules: [{ required: false, message: t("i18n.input") }],
        },
        {
          prop: "elevatorIds",
          label: "elevatorNo",
          type: "select",
          multiple: true,
          props: { label: "number", value: "id" },
          data: [],
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "installerId",
          label: "InstallationSupervision",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "verifierId",
          label: "auditor",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "configIds",
          label: "nodeContent",
          type: "select",
          multiple: true,
          props: {
            label: "item_simple_name",
            value: "id",
            disabled: "disabled",
          },
          data: [],
          rules: [{ required: false, message: t("i18n.select") }],
        },
        {
          prop: "id",
          show: false,
        },
      ],
      title: "modifyOrder",
    });

    const init = async (params) => {
      state.loading = true;
      if (params.acceptTime) {
        params.beginTime =
          proxy.$defined.format(params.acceptTime) + " 00:00:00";
      }
      if (params.accomplishTime) {
        params.accomplishTime =
          proxy.$defined.format(params.accomplishTime) + " 00:00:00";
      }
      params.elevatorNumber = params.elevatorName;
      params.installer = params.installerName;
      const { data } = await proxy.$api.system.getInstallWorkOrder(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const initData = () => {
      getBillPerson();
      addContractNodeConfig();
      getMainPerson();
    };

    const modifyData = (finishedData) => {
      modifyContractNodeConfig(finishedData);
      getBillPerson();
    };

    const getMainPerson = async () => {
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.handoverColumns[1].data = data;
      state.handoverColumns[2].data = data;
    };

    const getBillPerson = async () => {
      let { data } = await proxy.$api.maintenance.getBillPerson();
      state.addColumns.map((item) => {
        if (item.prop === "verifierId" || item.prop === "installerId") {
          item.data = data;
        }
      });
      state.columns.map((item) => {
        if (item.prop === "projectLeader") {
          item.data = data;
        }
      });
      state.reassignColumns.map((item) => {
        if (item.prop === "verifierId" || item.prop === "installerId") {
          item.data = data;
        }
      });
      state.modifyColumns.map((item) => {
        if (item.prop === "verifierId" || item.prop === "installerId") {
          item.data = data;
        }
      });
      state.handoverColumns.map((item) => {
        if (item.prop === "oldVerifierId" || item.prop === "verifierId") {
          item.data = data;
        }
      });
    };

    const addContractNodeConfig = async () => {
      let { data } = await proxy.$api.system.addContractNodeConfig();
      state.addColumns.map((item) => {
        if (item.prop === "configIds") {
          item.data = data;
        }
      });
      state.reassignColumns.map((item) => {
        if (item.prop === "configIds") {
          item.data = data;
        }
      });
    };

    const modifyContractNodeConfig = async (finishedData) => {
      let { data } = await proxy.$api.system.addContractNodeConfig();
      // console.log("finishedData:", finishedData);
      state.modifyColumns.map((item) => {
        if (item.prop === "configIds") {
          item.data = data;
          item.data.map((i) => {
            let fi = finishedData.find(x => x.itemId == i.id);
            // console.log(fi);
            i.disabled = !!(fi && fi.accomplish);
            // i.disabled = false;
            // console.log("disabled",i.disabled);
          });
        }
      });
    };

    const getEleByContract = async (id) => {
      console.log(state.addColumns[0].data);
      // let dataAll = [];
      // dataAll.push();
      let { data } = await proxy.$api.system.getInstallContractElevator(id, {
        number: "",
      });

      // data.unshift({number:'全选',id:'全选'})

      state.addColumns.map((item) => {
        if (item.prop === "elevatorIds") {
          item.isUpdate = true;
          item.data = data;
        }
      });
    };

    const handleDelete = (id) => {
      proxy.$defined.confirm(
        () => {
          proxy.$api.system.delInstallWorkOrder(id).then(() => {
            init(state.formInline);
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    const handleDialog = (name, flag, data) => {
      // console.log(data)
      state[name] = flag;
      state.title = data ? "reassignOrder" : "new";
      if (data) {
        let row = JSON.parse(JSON.stringify(data));
        state.reassignColumns.map((item) => {
          let arr = ["contractId", "elevatorIds", "configIds"];
          if (arr.indexOf(item.prop) > -1) {
            item.readonly = true;
            item.isUpdate = true;
          }
          if (item.prop === "contractId") {
            item.value = row.contractName;
          } else if (item.prop === "elevatorIds") {
            item.value = [];
            item.data.push({ id: row.elevatorId, number: row.elevatorName });
            item.value.push(row.elevatorId);
            //item.data = item.value;
          } else if (item.prop === "configIds") {
            item.value = [];
            row.operateModels.map((_x) => {
              item.value.push(_x.id);
            });
            // item.data = item.value;
          } else {
            item.value = row[item.prop];
          }
        });
        // console.log(state.reassignColumns)
      } else {
        state.addColumns.map((item) => {
          item.readonly = false;
          item.value = proxy.$defined.setNullValue(item.value); // 初始化
          item.data = [];
        });
        initData();
      }
    };
    const exportCompany = async (formInline) => {
      let res = await proxy.$api.system.exportInstall(formInline)
      proxy.$defined.exportFunc(res);
      console.log(res);
    }
    const handleModifyDialog = (name, flag, data) => {
      console.log(data);
      state[name] = flag;
      state.title = "modifyOrder";

      let row = JSON.parse(JSON.stringify(data));
      proxy.$api.system.getFinishedNode(row.id).then((rt) => {
        let { data: finishedData } = rt;
        modifyData(finishedData);
        console.log(finishedData);
      });

      state.modifyColumns.map((item) => {
        console.log("项目", item);
        console.log("row", row);

        let arr = ["contractId", "elevatorIds", "installerId", "verifierId"];
        if (arr.indexOf(item.prop) > -1) {
          item.readonly = true;
          item.isUpdate = true;
        }
        if (item.prop === "contractId") {
          item.value = row.contractName;
        } else if (item.prop === "elevatorIds") {
          item.value = [];
          item.data.push({ id: row.elevatorId, number: row.elevatorName });
          item.value.push(row.elevatorId);
        } else if (item.prop === "configIds") {
          item.value = [];
          row.operateModels.map((_x) => {
            item.value.push(_x.id);
          });
          item.isUpdate = true;
        } else {
          item.value = row[item.prop];
          // item.data = "ddd";
        }
      });
    };

    const submitAddForm = async (row) => {
      let direct_selling = null
      console.log(state.addColumns[0]);
      state.addColumns[0].data.forEach((item) => {
        if (item.id == row.contractId) {
          direct_selling = item.direct_selling
        }
      })
      row.configIds = direct_selling ? [
        "65152df6c21b58b9676c68e3c2ea3492",
        "034c89c519448967fb6c0a9dc1daa5d2",
        "6b1dc8bc9386bb5c51801f6887731669",
        "659a0f84c9ab85c9a8b9e78166b5fa41",
        "24155702f0d31bee74aa69a433094c93",
        "56a130d73e88b7b619dffcdfbf4cb25c",
        "bfcc36a8234f5734caf6585ee0edbce0",
        "0bdf6d5184cd452ad882b2bf5821cd7a",
      ] : [
        "034c89c519448967fb6c0a9dc1daa5d2",
        "24155702f0d31bee74aa69a433094c93",
        "659a0f84c9ab85c9a8b9e78166b5fa41",
        "56a130d73e88b7b619dffcdfbf4cb25c",
      ]
      console.log(row);
      // 工单改派 立即派发
      let data = row.id
        ? { installerId: row.installerId, verifierId: row.verifierId }
        : JSON.parse(JSON.stringify(row));

      console.log(data, "configIds");
      row.id
        ? await proxy.$api.system.setInstallWorkOrderPerson(row.id, data)
        : await proxy.$api.system.addInstallWorkOrder(row);
      proxy.$defined.tip(t("i18n.operation") + t("i18n.success"), "success");
      init(state.formInline);
      handleDialog("dialogAdd", false);
    };

    const reassignForm = async (row) => {
      // console.log(JSON.stringify(row))
      // 工单改派 立即派发
      let data = { installerId: row.installerId, verifierId: row.verifierId };

      await proxy.$api.system.setInstallWorkOrderPerson(
        state.reassignColumns[5].value,
        data
      );

      proxy.$defined.tip(t("i18n.operation") + t("i18n.success"), "success");
      init(state.formInline);
      handleDialog("dialogReassign", false);
    };

    const modifyForm = async (row) => {
      console.log(JSON.stringify(row))
      // 工单改派 立即派发
      let data = { configIds: row.configIds };

      await proxy.$api.system.modifyConfig(
        state.modifyColumns[5].value,
        data
      );

      proxy.$defined.tip(t("i18n.operation") + t("i18n.success"), "success");
      init(state.formInline);
      handleModifyDialog("dialogModify", false);
    };

    const submitHandoverForm = async (row) => {
      console.log(row);
      // 工作交接
      await proxy.$api.maintenance.handoverNew(row);
      state.dialogHandover = false;
      proxy.$defined.tip(t("i18n.handover") + t("i18n.success"), "success");
      init(state.formInline);
    };

    const remoteMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        setTimeout(() => {
          proxy.$api.system
            .getInstallContractJob({ number: val })
            .then((data) => {
              state[column][index].data = data.data;
              state[column][index].loading = false;
            });
        }, 1000);
      } else {
        state[column][index].data = [];
      }
    };

    initData();
    return {
      ...toRefs(state),
      init,
      handleDelete,
      handleDialog,
      handleModifyDialog,
      submitHandoverForm,
      remoteMethod,
      getEleByContract,
      submitAddForm,
      modifyForm,
      reassignForm,
    };
  },
};
</script>
